// RATE_UNLIMITED_API_SERVER_URL for the client code is gonna be undefined,
// since Gatsby injects only GATSBY_* variables
export const apiUrl =
  process.env.RATE_UNLIMITED_API_SERVER_URL ||
  process.env.GATSBY_API_SERVER_URL ||
  'http://localhost:8080';

const env = process.env.GATSBY_APP_ENV || 'local';

const domainsByEnv: { [envName: string]: string } = {
  production: 'ewebinar.com',
  test: 'ewebinar.com',
  development: 'dev.ewebinar.com',
};

const domain =
  domainsByEnv[env] || process.env.GATSBY_DOMAIN || process.env.DOMAIN_LOCAL || 'localhost';

const isLocal = env === 'local';
const isProduction = env === 'production' || env === 'test';

const widgetUrl: string = ((): string => {
  if (process.env.GATSBY_WIDGET_URL) {
    return process.env.GATSBY_WIDGET_URL;
  }

  return `https://app.${domain}/widget.js`;
})();

const config = {
  env,
  bugsnagEnv: process.env.BUGSNAG_ENV ?? env,
  noNetwork: false, // DD: Only go true when working without wifi
  isProduction,
  isLocal,
  showConsoleWindow: Boolean(process.env.GATSBC_SHOW_CONSOLE_WINDOW),
  isSSR: typeof window === 'undefined',
  isE2E: process.env.GATSBY_E2E_MODE === 'true' && env !== 'production',
  isTest: (process.env.GATSBY_ASSETS_CDN_URL ?? '').includes('assets.test.'), // NEW UI env
  GATSBY_ASSETS_CDN_URL: process.env.GATSBY_ASSETS_CDN_URL,
  API_SERVER_URL: apiUrl,
  GRAPHQL_SERVER_URL: process.env.GATSBY_GRAPHQL_SERVER_URL || `${apiUrl}/graphql`,
  CHAT_SERVER_URL: process.env.GATSBY_CHAT_SERVER_URL || `wss://chat-dev.ewebinar.com`,

  FORCE_BUNDLE_META: { name: 'use-bundle', content: 'yes' },

  STRIPE_PK:
    process.env.GATSBY_STRIPE_PK ||
    'pk_test_51HKGJ3Lpmi9frKeIdDpHNmd129WekXgDPJphbzOr4KTYHBYeJslMe39XGTYhqzmDTiT1fgeAMQ24cDNiIyiZ96Ox00FdM4VFcS',
  MICROSOFT_APP_ID: '25b79c44-46b7-4748-8303-0e2ab923e44f',
  VIMEO_BASE_URL: process.env.GATSBY_VIMEO_BASE_URL || 'https://api.vimeo.com',

  GATSBY_BUGSNAG_KEY: process.env.GATSBY_BUGSNAG_KEY,

  DEFAULT_PRIMARY_COLOR: '#39A1B2',
  DEFAULT_HIGHLIGHT_COLOR: '#FF7470',
  DEFAULT_LIMIT_PER_SESSION: 1000,

  CDN_REDIS_HOST: process.env.CDN_REDIS_HOST,
  CDN_REDIS_PORT: parseInt(process.env.CDN_REDIS_PORT || '6379', 10),

  URL_MARKETING_SITE: 'https://ewebinar.com',
  DOMAIN: domain,
  MAIN_FRONTEND_URL: process.env.MAIN_FRONTEND_URL || `https://app.${domain}`,
  SEEN_CONVERSATION_TIMEOUT: 100, //miliseconds
  TIME_TO_SEEK: 15, //seconds
  WELCOME_MESSAGE_TO_SHOW_AFTER_SECS: 10, // seconds - keep this in sync with backend
  TIME_TO_REMOVE_TYPING: 10000, // milliseconds
  UPLOAD_PROGRESS_MAX: process.env.GATSBY_UPLOAD_PROGRESS_MAX
    ? parseInt(process.env.GATSBY_UPLOAD_PROGRESS_MAX, 10)
    : 50, // Keep these synced between worker, frontend and backend

  ENABLE_SHOW_NOTIFICATIONS: true,
  TIME_TO_HIDDEN_NOTIFICATIONS: 5000,
  TIME_TO_HIDDEN_CONVERSION_NOTIFICATIONS: 7000,
  TIME_DELAY_BETWEEN_NOTIFICATIONS: 15000,
  GTM_KEY: process.env.GATSBY_GTM_KEY || 'GTM-KSHJWGG',
  POSTHOG_KEY: process.env.GATSBY_POSTHOG_KEY || 'phc_cPcFbOtdarR0FCZkLsocuIkzgdaT8SA3TSEtfEI7MLm',
  POSTHOG_HOST: process.env.GATSBY_POSTHOG_HOST || 'https://us.i.posthog.com',
  JUNE_KEY: process.env.GATSBY_JUNE_KEY || 'V1EcDVLdjjCsg8B0',

  TEMPLATE_TEAM: process.env.GATSBY_TEMPLATE_TEAM || '1',
  STANDARD_TEMPLATE_ID: process.env.GATSBY_STANDARD_TEMPLATE_ID || 'STANDARD',

  SECONDS_PER_LIKE_POINT: 10,
  MAX_LIKES_PER_LIKE_POINT: 7,

  NUMBER_OF_NOTIFICATION: 1,
  ACCEPTABLE_UPLOAD_FILE_TYPES: 'image/png, image/jpeg, image/jpg, image/gif',

  LOGO_MAX_WIDTH: 150,
  LOGO_MAX_HEIGHT: 60,

  HUBSPOT_ACCT: process.env.GATSBY_HUBSPOT_ACCT || '9236216',

  CONTENTFUL_SPACE_ID: process.env.CONTENTFUL_SPACE_ID || 'lfi6od6ddvrz',
  CONTENTFUL_ACCESS_TOKEN:
    process.env.CONTENTFUL_ACCESS_TOKEN || '7G8yM9rggrnwCNVqbH6G6N5YfXoOimFIpAbkdoxtnSQ',
  CONTENTFUL_HOST: process.env.CONTENTFUL_HOST || 'preview.contentful.com',

  WIDGET_URL: widgetUrl,
  TEMPLATE_PLACE_HOLDER: /\[>>(.*)<<\]|\[\&gt\;\&gt\;(.*)\&lt\;\&lt\;\]/g,

  UNSPLASH_ACCESS_KEY:
    process.env.UNSPLASH_ACCESS_KEY || 'Ro5oSr3zfkIzudVFZ37jVVQOo7z3vXnlUBeqpNBky_8',

  GIPHY_ACCESS_KEY: process.env.GIPHY_ACCESS_KEY || 'KQ16fVxLETzBlf7KCd3cEKsZvWcSLPIu',

  MINUTES_ALLOW_TO_REGISTER_AFTER_WEBINAR_START: 5,
  WIDGET_COUNT_DOWN_FROM_MINS: 60,
  WIDGET_DELAY_LOAD_IFRAME_SECS: parseInt(process.env.WIDGET_DELAY_LOAD_IFRAME_SECS || '1', 10),
  CAPTCHA_SITE_KEY: '6LcJV7AaAAAAAPjqENRYq-1Q1X__MetPiM2F178E',

  GA_TRACKING_CODE: process.env.GATSBY_GA_TRACKING_CODE || 'test',
  BOMBBOMB_CLIENT_ID:
    process.env.GATSBY_BOMBBOMB_CLIENT_ID || '82c55bd1-1cff-0554-ad53-a47fcef2d0fc',

  PACK_ATTENDEE_DATA_KEY: 'ewebinar',

  AWS_LOGGER_REGION: process.env.GATSBY_AWSLOG_REGION
    ? process.env.GATSBY_AWSLOG_REGION
    : isProduction
      ? 'ca-central-1'
      : 'us-east-1',
  AWS_LOGGER_ACCESS_KEY: process.env.GATSBY_AWSLOG_ACCESS || '',
  AWS_LOGGER_SECRET_KEY: process.env.GATSBY_AWSLOG_SECRET || '',

  GOOGLE_AUTH_CLIENT_ID:
    process.env.GATSBY_GOOGLE_AUTH_CLIENT_ID ||
    '294001223896-h3rgnq403u24qsq5hi1s71vnd7o7vc3i.apps.googleusercontent.com',
  MS_AUTH_CLIENT_ID: process.env.GATSBY_MS_AUTH_CLIENT_ID || 'b3980afc-27dc-4700-920b-c07bc6e08ed5',

  SOCKET_KEEP_ALIVE_INTERVAL: 1000 * 60 * 5,

  CW_LOGGER_URL:
    process.env.CW_LOGGER_URL ||
    'https://4qaslqcv54wxj35vqd2z2gabaa0pddlm.lambda-url.us-east-1.on.aws/',
};

export default config;
